<template>
  <div class="block">
    <el-page-header @back="dynamic" content="人员配置" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text">
        <el-button size="mini" type="primary" @click="outerVisible = true;">新增</el-button>
        <el-button size="mini" type="primary">导入</el-button>
        <template>
          <el-select v-model="value" filterable placeholder="请选择" style="margin-left: 50px;width: 100px" size="mini">
            <el-option
                v-for="item in depData"
                :key="item.depId"
                :label="item.depName"
                :value="item.depId">
            </el-option>
          </el-select>
        </template>
      </el-button>
      <el-dialog title="添加" :visible.sync="outerVisible">
        <el-form :model="form">
          <el-form-item label="姓名" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-input v-model="form.empName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-radio v-model="form.empSexy" label="1">男</el-radio>
            <el-radio v-model="form.empSexy" label="0">女</el-radio>
          </el-form-item>
          <el-form-item label="身份证号" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-input v-model="form.empNum" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="部门" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-select v-model="form.depId" placeholder="请选择">
              <el-option
                  v-for="item in depData"
                  :key="item.depId"
                  :label="item.depName"
                  :value="item.depId">
                <span style="float: left">{{ item.depName }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="岗位" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-input v-model="form.empPost" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="入职日期" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-date-picker
                v-model="form.empDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-dialog
            width="20%"
            title="警告"
            :visible.sync="innerVisible"
            append-to-body>
          <span>您将新增一条记录，请确认！</span>
          <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="innerVisible = false;outerVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="resetForm(); innerVisible = false; outerVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" @click="outerVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="innerVisible = true">提交</el-button>
        </div>
      </el-dialog>
    </template>
    <el-table
        :data="empData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}"
        height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="empName"
          label="姓名">
      </el-table-column>
      <el-table-column
          prop="empSexy"
          label="性别"
          width="50">
        <template slot-scope="scope">
          {{scope.row.empSexy ===0 ? '女' : '男'}}
        </template>
      </el-table-column>
      <el-table-column
          prop="birthday"
          label="出生年月"
          width="100">
      </el-table-column>
      <el-table-column
          prop="empNum"
          label="身份证号"
          width="200">
      </el-table-column>
      <el-table-column
          prop="depId"
          label="部门"
          width="100">
        <template slot-scope="scopeo">
          {{ getdepName(scopeo.row.depId) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="empPost"
          label="岗位"
          width="210">
      </el-table-column>
      <el-table-column
          prop="empDate"
          label="入职日期"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope1">
          <el-button
              @click.native.prevent="open(scope1.row.empId)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button
              @click.native.prevent="queryEmpById(scope1.row.empId);"
              style="padding: 2px; margin: 5px 0px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="outerEmp">
      <el-form :model="editForm">
        <el-form-item label="姓名" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-input v-model="editForm.empName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-radio v-model="editForm.empSexy" label="1">男</el-radio>
          <el-radio v-model="editForm.empSexy" label="0">女</el-radio>
        </el-form-item>
        <el-form-item label="身份证号" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-input v-model="editForm.empNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="部门" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-select v-model="editForm.depId" placeholder="请选择">
            <el-option
                v-for="item in depData"
                :key="item.depId"
                :label="item.depName"
                :value="item.depId">
              <span style="float: left">{{ item.depName }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-input v-model="editForm.empPost" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="入职日期" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-date-picker
              v-model="editForm.empDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-dialog
          width="20%"
          title="警告"
          :visible.sync="innerEmp"
          append-to-body>
        <span>您将新增一条记录，请确认！</span>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="innerEmp = false;outerEmp = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="editEmp(); innerEmp = false; outerEmp = false">确 定</el-button>
          </span>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="outerEmp = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="innerEmp = true">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "employee",
  data() {
    return {
      outerEmp: false,
      outerVisible: false,
      innerEmp: false,
      innerVisible: false,
      form: {
        empName: '',
        empSexy: '1',
        empNum: '',
        depId: '',
        empPost: '',
        empDate: ''
        // delivery: false
      },
      editForm: {
        empName: '',
        empSexy: '1',
        empNum: '',
        depId: '',
        empPost: '',
        empDate: ''
      },
      formLabelWidth: '120px',
      empData: [],
      depData: [],
      value: ''
    }
  },
  mounted() {
    this.getEmpData()
    this. getDepData()
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    dynamic() {
      this.$router.push("dynamic")
    },
    getEmpData() {
      this.$http({
        method: 'get',
        url: '/api/emp/getEmpList',
        data: []
      }).then((res)=>{
        this.empData = res.data.data
      })
    },
    getDepData() {
      this.$http({
        method: 'get',
        url: '/api/dep/getDepList',
        data: '',
      }).then((res)=> {
        this.depData = res.data.data
      })
    },
    resetForm() {
      this.$http({
        method: 'post',
        url: '/api/emp/insertEmp',
        data: this.form,
      }).then((res)=> {
        alert(res.data.msg)
        if (res.data.status) {
          this.form = {}
          this.getEmpData()
        }
      })
      this.form = '';
    },
    getdepName(id) {
      for (var i =0; i < this.depData.length; i++) {
        if (this.depData[i].depId === id) {
          return this.depData[i].depName
        }
      }
    },
    queryEmpById(empId) {
      this.$http({
        method: 'get',
        url: '/api/emp/queryEmpById/' + empId,
        data: ''
      }).then((res) =>{
        this.editForm = res.data.data
        this.outerEmp = true
      })
    },
    open(empId) {
      this.$confirm('删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'delete',
          url: '/api/emp/deleteEmpById/' +empId
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.getEmpData()
        }).catch((res) =>{
          console.log(res)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      });
    },
    editEmp() {
      this.$http({
        method: 'put',
        url: '/api/emp/editEmpById',
        data: this.editForm
      }).then((res) =>{
        this.$message({
          type: 'success',
          message: res.data.msg
        });
        this.getEmpData()
      }).catch((res)=>{
        this.$message({
          type: 'info',
          message: res.data.msg
        });
      })
    }
  }
};
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>